export enum DevelopStage {
  local = 'local',
  dev = 'dev',
  pre = 'pre',
  prod = 'prod'
}

export type Environment = {
  production: boolean,
  apiUrl: string,
  stage: DevelopStage,
  appVersion: string,
  USERDATA_KEY: string,
  encryptionKey: string,
  appName: string,
  [key: string]: any
}


export const GLOBAL_ENVIRONMENT_VALUES = {
  production: false,
  apiUrl: "undefined",
  stage: DevelopStage.local,
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  encryptionKey: '0123456789abcdef0123456789abcdef',
  appName: 'AI4KIDNEY',
  logo: 'assets/media/kidney/logo.svg'
}
